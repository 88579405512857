import React, { Suspense } from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
const AuthRoute = React.lazy(() => import('./views/components/AuthRoute'));

const App = ({theme}) => {
  return (
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <AuthRoute path="/" name="login"/>
            </Switch>
          </Suspense>
        </BrowserRouter>
  );
}



export default App;